import * as React from 'react'
import {Modal as BSModal, Button} from 'react-bootstrap'
import {ButtonWithIcon, SupportedIcons} from '../ButtonWithIcon/ButtonWithIcon'

interface ModalButton {
    label: string
    variant: string
    onClick?: () => void
    leftIcon?: SupportedIcons
    rightIcon?: SupportedIcons
    iconSize?: number
    dataTestId?: string
    disabled?: boolean
}

interface ModalProps {
    title: string
    children: React.ReactNode
    buttons: ModalButton[]
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}

/**
 * Modal component
 *
 * Modal will close upon clicking any button. You do not need to include this in each buttons 'onClick' parameter.
 *
 * @param {ModalProps} [props] - React props
 * @param {string} [props.title] - modal title
 * @param {React.ReactNode} [props.children] - child component(s) used as the modal body
 * @param {ModalButton[]} [props.buttons] - array of buttons for the modal footer, recommended max of 2 buttons
 * @param {boolean} [props.isOpen] - whether the modal is open
 * @param {function(value: boolean): void} [props.setIsOpen] - function to mutate the isOpen flag
 * @returns {React.ReactComponentElement} - component
 */
const Modal = ({title, children, buttons, isOpen, setIsOpen}: ModalProps) => {
    return (
        <BSModal show={isOpen} onHide={() => setIsOpen(false)} centered aria-labelledby="modal-title">
            <BSModal.Dialog>
                <BSModal.Header closeButton>
                    <BSModal.Title id="modal-title">{title}</BSModal.Title>
                </BSModal.Header>

                <BSModal.Body>{children}</BSModal.Body>

                <BSModal.Footer>
                    {buttons.map((button, i) => {
                        if (button.leftIcon || button.rightIcon) {
                            return (
                                <ButtonWithIcon
                                    key={`modal-button--${i}`}
                                    data-testid={button.dataTestId}
                                    className="col"
                                    variant={button.variant}
                                    leftIcon={button.leftIcon}
                                    rightIcon={button.rightIcon}
                                    iconSize={button.iconSize || 16}
                                    onClick={() => {
                                        if (button.onClick) {
                                            button.onClick()
                                        }
                                        setIsOpen(false)
                                    }}
                                    disabled={button.disabled}
                                >
                                    {button.label}
                                </ButtonWithIcon>
                            )
                        }
                        return (
                            <Button
                                key={`modal-button--${i}`}
                                data-testid={button.dataTestId}
                                className="col"
                                variant={button.variant}
                                onClick={() => {
                                    if (button.onClick) {
                                        button.onClick()
                                    }
                                    setIsOpen(false)
                                }}
                                disabled={button.disabled}
                            >
                                {button.label}
                            </Button>
                        )
                    })}
                </BSModal.Footer>
            </BSModal.Dialog>
        </BSModal>
    )
}

export default Modal
