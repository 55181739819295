/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    InvestorCommunicationState,
    InvestorCommunicationStateFromJSON,
    InvestorCommunicationStateFromJSONTyped,
} from './'

/**
 *
 * @export
 * @interface InvestorCommunicationResponse
 */
export interface InvestorCommunicationResponse {
    /**
     *
     * @type {string}
     * @memberof InvestorCommunicationResponse
     */
    id: string
    /**
     *
     * @type {Date}
     * @memberof InvestorCommunicationResponse
     */
    created_at: DateTime
    /**
     *
     * @type {string}
     * @memberof InvestorCommunicationResponse
     */
    title: string
    /**
     *
     * @type {string}
     * @memberof InvestorCommunicationResponse
     */
    content: string
    /**
     *
     * @type {string}
     * @memberof InvestorCommunicationResponse
     */
    audience: string
    /**
     *
     * @type {InvestorCommunicationState}
     * @memberof InvestorCommunicationResponse
     */
    state: InvestorCommunicationState
    /**
     *
     * @type {Date}
     * @memberof InvestorCommunicationResponse
     */
    schedule_send_at: DateTime
    /**
     *
     * @type {Date}
     * @memberof InvestorCommunicationResponse
     */
    submitted_at?: DateTime
    /**
     *
     * @type {Date}
     * @memberof InvestorCommunicationResponse
     */
    approved_at?: DateTime
    /**
     *
     * @type {Date}
     * @memberof InvestorCommunicationResponse
     */
    sent_at?: DateTime
    /**
     *
     * @type {Date}
     * @memberof InvestorCommunicationResponse
     */
    last_saved_at: DateTime
}

export function InvestorCommunicationResponseFromJSON(json: any): InvestorCommunicationResponse {
    return InvestorCommunicationResponseFromJSONTyped(json, false)
}

export function InvestorCommunicationResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InvestorCommunicationResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        id: json['id'],
        created_at: DateTime.fromISO(json['created_at'], {setZone: true}),
        title: json['title'],
        content: json['content'],
        audience: json['audience'],
        state: InvestorCommunicationStateFromJSON(json['state']),
        schedule_send_at: DateTime.fromISO(json['schedule_send_at'], {setZone: true}),
        submitted_at: !exists(json, 'submitted_at')
            ? undefined
            : DateTime.fromISO(json['submitted_at'], {setZone: true}),
        approved_at: !exists(json, 'approved_at') ? undefined : DateTime.fromISO(json['approved_at'], {setZone: true}),
        sent_at: !exists(json, 'sent_at') ? undefined : DateTime.fromISO(json['sent_at'], {setZone: true}),
        last_saved_at: DateTime.fromISO(json['last_saved_at'], {setZone: true}),
    }
}

export function InvestorCommunicationResponseToJSON(value?: InvestorCommunicationResponse | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        id: value.id,
        created_at: value.created_at.toJSDate().toISOString(),
        title: value.title,
        content: value.content,
        audience: value.audience,
        state: InvestorCommunicationStateToJSON(value.state),
        schedule_send_at: value.schedule_send_at.toJSDate().toISOString(),
        submitted_at: value.submitted_at === undefined ? undefined : value.submitted_at.toJSDate().toISOString(),
        approved_at: value.approved_at === undefined ? undefined : value.approved_at.toJSDate().toISOString(),
        sent_at: value.sent_at === undefined ? undefined : value.sent_at.toJSDate().toISOString(),
        last_saved_at: value.last_saved_at.toJSDate().toISOString(),
    }
}
