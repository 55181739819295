/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface CompanyInvestorCommunicationStatsResponse
 */
export interface CompanyInvestorCommunicationStatsResponse {
    /**
     *
     * @type {string}
     * @memberof CompanyInvestorCommunicationStatsResponse
     */
    total_delivered: string
    /**
     *
     * @type {string}
     * @memberof CompanyInvestorCommunicationStatsResponse
     */
    total_unique_opens: string
    /**
     *
     * @type {string}
     * @memberof CompanyInvestorCommunicationStatsResponse
     */
    open_rate: string
}

export function CompanyInvestorCommunicationStatsResponseFromJSON(
    json: any,
): CompanyInvestorCommunicationStatsResponse {
    return CompanyInvestorCommunicationStatsResponseFromJSONTyped(json, false)
}

export function CompanyInvestorCommunicationStatsResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CompanyInvestorCommunicationStatsResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        total_delivered: json['total_delivered'],
        total_unique_opens: json['total_unique_opens'],
        open_rate: json['open_rate'],
    }
}

export function CompanyInvestorCommunicationStatsResponseToJSON(
    value?: CompanyInvestorCommunicationStatsResponse | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        total_delivered: value.total_delivered,
        total_unique_opens: value.total_unique_opens,
        open_rate: value.open_rate,
    }
}
