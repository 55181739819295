import * as React from 'react'
import {Navbar, Container, Nav} from 'react-bootstrap'
import {Outlet} from 'react-router-dom'
import {NavLink} from '~src/components/Navigation/Navigation'

export const StaffSharesLayout: React.FunctionComponent = () => {
    return (
        <>
            <StaffSharesNav />
            <div className="container py-4 d-flex gap-3 flex-column">
                <Outlet />
            </div>
        </>
    )
}

const StaffSharesNav = () => {
    return (
        <Navbar>
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Nav className="me-auto gap-2">
                    <NavLink label="Overview" to="/staff-shares" />
                    <NavLink label="Employees" to="/#" />
                </Nav>
            </Container>
        </Navbar>
    )
}
