import cn from 'classnames'
import * as React from 'react'
import * as styles from './Callout.module.scss'

export type CalloutType = 'secondary' | 'info' | 'warning' | 'danger'

interface CalloutProps {
    children: JSX.Element
    type: CalloutType
}

export const Callout: React.FunctionComponent<CalloutProps> = ({children, type}) => {
    return <div className={cn(styles.callout, styles[type])}>{children}</div>
}
