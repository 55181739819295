@import '../../../_assets/styles/config.module';

.navigationWrapper {
    position: sticky;
    top: 0;
    z-index: 999;
}

.main {
    @include container();
    box-sizing: border-box;
}
