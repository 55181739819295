import cn from 'classnames'
import {SendIcon} from 'lucide-react'
import * as React from 'react'
import {Badge, Col, Row} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import * as styles from './Communications.module.scss'
import {ButtonWithIcon} from '~src/components/ButtonWithIcon/ButtonWithIcon'
import {Error} from '~src/components/Error/Error'
import {useCommunicationStatsV2} from '~src/hooks/communications'
import {useCurrentCompany} from '~src/hooks/useCurrentCompany'
import {useCurrentStaff} from '~src/hooks/useCurrentStaff'
import {getAudienceLabel} from '~src/util/audienceHelpers'
import {isFeatureEnabled} from '~src/util/featureFlags'
import {formatNumber} from '~src/util/formatNumber'

export const ViewCommunication = () => {
    const navigate = useNavigate()
    const {communicationId} = useParams()

    const investorComms = useCurrentCompany().investor_communications
    const communication = investorComms.find(c => c.id === communicationId)
    const communicationStats = useCommunicationStatsV2({communicationId: communicationId!})

    const currentStaff = useCurrentStaff()

    if (!communication || !communication.sent_at) {
        return <Error error={undefined} />
    }

    const communicationItems = [
        {name: 'Sent on', value: communication.sent_at.toLocal().toFormat("dd MMMM yyyy 'at' h:mm a")},
        {name: 'Title/Subject line', value: communication.title},
        {name: 'Communication content', value: communication.content, is_html_content: true},
    ]
    if (isFeatureEnabled(currentStaff.company_id, 'SEND_COMMS_TO_WATCHLIST')) {
        communicationItems.splice(1, 0, {name: 'Audience', value: getAudienceLabel(communication.audience)})
    }

    const hasMultipleAudiences = Object.keys(communicationStats.stats_by_audience).length > 1
    const totalDelivered = Object.values(communicationStats.stats_by_audience).reduce((acc, stats) => {
        return acc + parseFloat(stats.total_delivered)
    }, 0)
    const totalOpened = Object.values(communicationStats.stats_by_audience).reduce((acc, stats) => {
        return acc + parseFloat(stats.total_unique_opens)
    }, 0)

    const avgOpenRate = totalDelivered > 0 ? (totalOpened / totalDelivered) * 100 : 0

    return (
        <div className="container">
            <ButtonWithIcon
                className="mt-3 mb-1"
                variant="ghost"
                leftIcon="arrowLeft"
                iconSize={16}
                onClick={() => navigate(-1)}
            >
                Back
            </ButtonWithIcon>

            <h1 className="h3 mt-2">{communication.title}</h1>

            {/** check the communicationId matches to prevent showing incorrect stats when switching between comms */}
            {communicationStats.communication_id === communication.id && (
                <Row className="mt-3">
                    {hasMultipleAudiences && (
                        <Col xs={12} sm={12} md={12} lg={4} className="mb-3">
                            <p className={cn(styles.statsTitle, 'mb-1')}>Total</p>
                            <EmailEngagementStats
                                totalDelivered={totalDelivered.toString()}
                                totalUniqueOpens={totalOpened.toString()}
                                openRate={avgOpenRate.toString()}
                            />
                        </Col>
                    )}
                    {Object.entries(communicationStats.stats_by_audience).map(([audience, stats]) => {
                        return (
                            <Col
                                key={audience}
                                xs={12}
                                sm={12}
                                md={hasMultipleAudiences ? 6 : 12}
                                lg={hasMultipleAudiences ? 4 : 12}
                                className="mb-3"
                            >
                                <p className={cn(styles.statsTitle, 'mb-1')}>
                                    {audience.toLowerCase().charAt(0).toUpperCase()}
                                    {audience.substring(1).toLowerCase()}
                                </p>
                                <EmailEngagementStats
                                    totalDelivered={stats.total_delivered}
                                    totalUniqueOpens={stats.total_unique_opens}
                                    openRate={stats.open_rate}
                                />
                            </Col>
                        )
                    })}
                </Row>
            )}

            <div className={cn(styles.container, 'd-flex gap-3 flex-column mb-3')}>
                <div className={styles.containerHeader}>
                    <SendIcon size={16} />
                    <h2 className="flex-grow-1 my-0 ms-1 h5">Communication details</h2>
                </div>
                <div className={styles.viewCommunication}>
                    {communicationItems.map((item, i) => (
                        <Row className={cn(styles.itemRow, 'mb-3')} key={i}>
                            <Col className={styles.itemName} xs={4}>
                                {item.name}
                            </Col>
                            <Col className={styles.itemValue}>
                                {!item.is_html_content && <>{item.value}</>}
                                {item.is_html_content && <div dangerouslySetInnerHTML={{__html: item.value}}></div>}
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>
        </div>
    )
}

interface EmailEngagementStatsProps {
    totalDelivered: string
    totalUniqueOpens: string
    openRate: string
}

const EmailEngagementStats = ({totalDelivered, totalUniqueOpens, openRate}: EmailEngagementStatsProps) => {
    const hasOpenRate = parseFloat(openRate) > 0

    return (
        <div className="container-fluid">
            <Row className={styles.container}>
                <Col className="px-0">
                    <p className={cn(styles.strong, 'mt-3')}>Delivered</p>
                    <p className="h3 mb-3">{formatNumber({number: totalDelivered, decimalPlaces: 0})}</p>
                </Col>
                <Col className="px-0">
                    <p className={cn(styles.strong, 'mt-3')}>
                        Opened{' '}
                        {hasOpenRate && (
                            <Badge className="align-middle ms-1" bg="light" text="dark" pill>
                                {formatNumber({number: openRate, decimalPlaces: 0})}%
                            </Badge>
                        )}
                    </p>
                    <p className="h3">
                        {formatNumber({
                            number: totalUniqueOpens,
                            decimalPlaces: 0,
                        })}
                    </p>
                </Col>
            </Row>
        </div>
    )
}
