import {Exchange} from '~src/api'

interface EssCompany {
    id: string
    name: string
    ownerOfShares: string
    instrumentName: string
    exchange: Exchange
    isPrimary: boolean
    schemes: Scheme[]
}

interface Scheme {
    id: string
    name: string
    schemeType: string
    allocations: Allocation[]
    employees: EmployeeStats
}

interface Allocation {
    name: string
    allocated: string
    unvested: string
    vested: string
    exercised: string
    participants: string
}

interface EmployeeStats {
    current: string
    removed: string
}

export const retailBackendResponse: EssCompany[] = [
    {
        id: 'sample_uuid',
        name: 'Company Name',
        ownerOfShares: 'Company NZ LLC',
        instrumentName: 'COM',
        exchange: Exchange.NZX,
        isPrimary: true,
        schemes: [
            {
                id: 'sample_uuid',
                name: 'Company Equity',
                schemeType: 'Share rights',
                allocations: [
                    {
                        name: '2025 Company Share',
                        allocated: '4000',
                        unvested: '4000',
                        vested: '0',
                        exercised: '0',
                        participants: '500',
                    },
                    {
                        name: '2024 Company Share',
                        allocated: '1000',
                        unvested: '800',
                        vested: '200',
                        exercised: '0',
                        participants: '300',
                    },
                    {
                        name: '2023 Company Share',
                        allocated: '100',
                        unvested: '20',
                        vested: '80',
                        exercised: '0',
                        participants: '50',
                    },
                ],
                employees: {
                    current: '500',
                    removed: '20',
                },
            },
            {
                id: 'sample_uuid',
                name: 'Company Share',
                schemeType: 'Shares',
                allocations: [
                    {
                        name: '2025 Company Share',
                        allocated: '4000',
                        unvested: '3500',
                        vested: '500',
                        exercised: '0',
                        participants: '150',
                    },
                ],
                employees: {
                    current: '150',
                    removed: '0',
                },
            },
        ],
    },
    {
        id: 'sample_uuid',
        name: 'Company Name',
        ownerOfShares: 'Company AU LLC',
        instrumentName: 'COP',
        exchange: Exchange.ASX,
        isPrimary: false,
        schemes: [],
    },
]
