import {useSetAtom} from 'jotai'
import * as React from 'react'
import {Outlet} from 'react-router-dom'
import * as styles from './_assets/styles.module.scss'
import {useCurrentStaff} from '~src/hooks/useCurrentStaff'
import {NavigationLayout} from '~src/sections/Navigation/NavigationLayout'
import {currentStaffAtom} from '~src/state/currentStaff'

export const BaseLayout = () => {
    const setCurrentStaff = useSetAtom(currentStaffAtom)
    setCurrentStaff(useCurrentStaff())

    return (
        <>
            <div className={styles.navigationWrapper}>
                <NavigationLayout />
            </div>
            <main className={styles.main} id="content">
                <Outlet />
            </main>
        </>
    )
}
