import {useAuth0} from '@auth0/auth0-react'
import cn from 'classnames'
import {useAtom} from 'jotai'
import {LogOut, ChevronDown} from 'lucide-react'
import * as React from 'react'
import {Container, Nav, NavDropdown, Navbar, Breadcrumb, Card} from 'react-bootstrap'
import {useCookies} from 'react-cookie'
import {useLocation, Link, useNavigate} from 'react-router-dom'
import pineappleLogo from '../../_assets/images/logos/pineapple-logo.svg'
import {useCurrentCompany} from '~src/hooks/useCurrentCompany'
import {useCreateLogout} from '~src/hooks/useLogout'
import {currentCompanyAtom} from '~src/state/currentCompany'
import {preferencesAtom} from '~src/state/preferences'

const isRouteActive = (routeUrl: string): boolean => {
    return location.pathname.includes(routeUrl)
}

export const Navigation = () => {
    const [currentCompany, setCurrentCompany] = useAtom(currentCompanyAtom)
    setCurrentCompany(useCurrentCompany())

    const location = useLocation()

    const {logout} = useAuth0()

    const [_cookies, _setCookie, removeCookie] = useCookies(['auth0_jwt'])

    const removeCookieAndLogout = () => {
        // Remove the JWT cookie we use to auth with the API & Dash
        removeCookie('auth0_jwt')

        // Trigger the auth0 logout
        logout({
            logoutParams: {returnTo: window.location.origin},
        })
    }

    const createLogout = useCreateLogout({
        onSuccess: () => {
            removeCookieAndLogout()
        },
        onError: () => {
            // Even if we fail to call the API we shouldn't
            // prevent the user from removing their cookie
            // and logging out of the app
            removeCookieAndLogout()
        },
    })

    const isRouteHome = location.pathname === '/'

    const onLogout = () => {
        // Make an API call to record that we are logging out this JWT then trigger
        // the other logout steps
        createLogout.mutate({})
    }

    return (
        <>
            <Navbar expand="sm" id="navigation">
                <Container>
                    <div className="d-flex ">
                        <Navbar.Brand href="/">
                            <img
                                src={pineappleLogo}
                                className="d-inline-block align-top sharesies-open-logo"
                                alt="Sharesies Open"
                            />
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto gap-2">
                                <NavLink
                                    label="Home"
                                    to="/communications"
                                    isActive={isRouteActive('/communications') || isRouteHome}
                                />
                                <NavLink
                                    label="Insights"
                                    to="/insights/shareholder-activity"
                                    isActive={isRouteActive('/insights/')}
                                />
                            </Nav>
                        </Navbar.Collapse>
                    </div>

                    <div>
                        <NavDropdown
                            data-testid="button--nav-dropdown"
                            className="px-2 py-1 border rounded bg-white"
                            title={
                                <span>
                                    <img
                                        className="rounded-1"
                                        src={currentCompany ? currentCompany.logo_url : ''}
                                        alt={currentCompany ? `${currentCompany.name} logo` : 'logo'}
                                        style={{maxWidth: 80, maxHeight: 30}}
                                    />
                                    <span className="px-1 d-inline-block align-middle dropdown-name">
                                        {currentCompany ? currentCompany.name : 'Loading'}
                                    </span>
                                    <ChevronDown size={16} color="#000000" opacity={0.5} />
                                </span>
                            }
                            id="basic-nav-dropdown"
                        >
                            <NavDropdown.Item data-testid="button--logout" onClick={() => onLogout()}>
                                <LogOut size={16} />
                                <span className="ps-1">Logout</span>
                            </NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </Container>
            </Navbar>
            {isRouteActive('/insights/') && <InsightsNav />}
        </>
    )
}

const InsightsNav = () => {
    const navigate = useNavigate()
    const [preferences, setPreferences] = useAtom(preferencesAtom)

    React.useEffect(() => {
        if (preferences.insightsTab && !isRouteActive(`/insights/${preferences.insightsTab}`)) {
            navigate(`/insights/${preferences.insightsTab}`)
        }
    }, [])

    return (
        <Navbar>
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Nav className="me-auto gap-2">
                    <NavLink
                        label="Shareholder activity"
                        to="/insights/shareholder-activity"
                        onClick={() => setPreferences({...preferences, insightsTab: 'shareholder-activity'})}
                    />
                    <NavLink
                        label="Shareholder breakdown"
                        to="/insights/shareholder-breakdown"
                        onClick={() => setPreferences({...preferences, insightsTab: 'shareholder-breakdown'})}
                    />
                </Nav>
            </Container>
        </Navbar>
    )
}

export const NavLink = ({
    to,
    label,
    isActive,
    onClick,
}: {
    to: string
    label: string
    isActive?: boolean
    onClick?: () => void
}) => {
    return (
        <Link
            className={cn(
                'nav-link', // bootstrap nav link
                (isActive !== undefined ? isActive : isRouteActive(to)) && 'is-nav-active',
                'rounded',
            )}
            to={to}
            onClick={onClick}
        >
            {label}
        </Link>
    )
}

interface BreadcrumbItem {
    label: string
    url: string
}

/**
 * Navigation breadcrumb component.
 * The last item will be displayed as active (non-clickable). Items are rendered in the order provided.
 * @param {BreadcrumbItem[]} props.breadcrumbItems - Array of breadcrumb items to display. Each breadcrumb item requires a label and URL.
 * @example
 * <NavBreadcrumb
 *   breadcrumbItems={[
 *     { label: 'Staff Shares', url: '/staff-shares' },
 *     { label: 'Scheme', url: '/staff-shares/scheme' }
 *   ]}
 * />
 */
export const NavBreadcrumb = ({breadcrumbItems}: {breadcrumbItems: BreadcrumbItem[]}) => {
    return (
        <div className="mb-3 d-flex align-items-start">
            <Card className="border-0 px-3 py-2">
                <Breadcrumb>
                    {breadcrumbItems.map((item, i) => {
                        const isLast = i === breadcrumbItems.length - 1
                        return (
                            <Breadcrumb.Item key={item.url} linkAs={Link} href={item.url} active={isLast}>
                                {item.label}
                            </Breadcrumb.Item>
                        )
                    })}
                </Breadcrumb>
            </Card>
        </div>
    )
}
