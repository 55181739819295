import Decimal from 'decimal.js'
import * as React from 'react'
import {Col, Row, Stack, Table} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import * as data from './ExampleData'
import * as styles from './StaffShares.module.scss'
import {
    ContentContainer,
    ContentContainerHeader,
    ContentSection,
} from '~src/components/ContentContainer/ContentContainer'
import {NavBreadcrumb} from '~src/components/Navigation/Navigation'
import {useCurrentCompany} from '~src/hooks/useCurrentCompany'
import {formatNumberWithThousandsSeparator} from '~src/util/formatNumber'

//TODO add legal owner of company shares to company response.

export const StaffSharesOverview: React.FunctionComponent = () => {
    const company = useCurrentCompany()
    const selectedEssCompany = data.retailBackendResponse.find(company => company.isPrimary)

    return (
        <>
            <NavBreadcrumb
                breadcrumbItems={[
                    {label: 'Staff Shares', url: '/staff-shares'},
                    {label: 'Overview', url: '/staff-shares'},
                ]}
            />
            <h1 className="h2 mb-0">{company.name} Staff Share Scheme Overview</h1>
            <ContentSection>
                <h2 className="h6 mb-0">Holdings</h2>
                <ContentContainer className="pt-3">
                    <Table hover className="mb-1">
                        <thead className="bg-light">
                            <tr>
                                <th>Name</th>
                                <th>Code</th>
                                <th>Exchange</th>
                                <th>Shares in Portfolio</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.retailBackendResponse.map(company => (
                                <tr key={company.id}>
                                    <td>{company.name}</td>
                                    <td>{company.instrumentName}</td>
                                    <td>{company.exchange}</td>
                                    <td>{`${formatNumberWithThousandsSeparator('6500')}`}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </ContentContainer>
            </ContentSection>
            <ContentSection>
                <h2 className="h6 mb-0">Schemes</h2>
                {selectedEssCompany?.schemes.map(scheme => (
                    <ContentContainer key={scheme.id}>
                        <ContentContainerHeader className="pt-3">
                            <h3 className="flex-grow-1 m-0">{scheme.name}</h3>
                            <Stack direction="horizontal" gap={3}>
                                <p className="m-0">Exchange: {selectedEssCompany.exchange}</p>
                                <p className="m-0">Scheme type: {scheme.schemeType}</p>
                            </Stack>
                        </ContentContainerHeader>

                        <Row className="flex-fill">
                            <Col sm={12} md={6} lg={6} xl={true}>
                                <SchemeStatsCard
                                    title={scheme.schemeType}
                                    linkTo={{label: 'View all', url: ''}}
                                    stats={[
                                        {
                                            label: 'Vested',
                                            value: `${formatNumberWithThousandsSeparator(
                                                Decimal.sum(
                                                    ...scheme.allocations.map(allocation => allocation.vested),
                                                ).toString(),
                                            )}`,
                                        },
                                        {
                                            label: 'Unvested',
                                            value: `${formatNumberWithThousandsSeparator(
                                                Decimal.sum(
                                                    ...scheme.allocations.map(allocation => allocation.unvested),
                                                ).toString(),
                                            )}`,
                                        },
                                    ]}
                                />
                            </Col>
                            <Col sm={12} md={6} lg={6} xl={true}>
                                <SchemeStatsCard
                                    title="Employees"
                                    linkTo={{label: 'View all', url: ''}}
                                    stats={[
                                        {
                                            label: 'Current',
                                            value: `${formatNumberWithThousandsSeparator(scheme.employees.current)}`,
                                        },
                                        {
                                            label: 'Removed',
                                            value: `${formatNumberWithThousandsSeparator(scheme.employees.removed)}`,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                        <div>
                            <h4 className="h6">Unvested allocations</h4>
                            <Table hover className="mb-1">
                                <thead className="bg-light">
                                    <tr>
                                        <th>Scheme name and year</th>
                                        <th>Number of shares</th>
                                        <th>Enrolled employees</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {scheme.allocations.map(allocation => (
                                        <tr key={allocation.name}>
                                            <td>{allocation.name}</td>
                                            <td>{`${formatNumberWithThousandsSeparator(allocation.allocated)}`}</td>
                                            <td>{`${formatNumberWithThousandsSeparator(allocation.participants)}`}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <strong>Total allocated</strong>
                                        </td>
                                        <td colSpan={2}>
                                            <strong>{`${formatNumberWithThousandsSeparator('22000')}`}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </ContentContainer>
                ))}
            </ContentSection>
        </>
    )
}

interface LinkProps {
    label: string
    url: string
}

interface StatProps {
    label: string
    value: string
}

interface SchemeStatsCardProps {
    title: string
    linkTo: LinkProps
    stats: StatProps[]
}

const SchemeStatsCard: React.FC<SchemeStatsCardProps> = ({title, linkTo, stats}) => {
    return (
        <div className={styles.schemeStatsCard}>
            <div className="d-flex gap-2 align-items-center">
                <h4 className={styles.title}>{title}</h4>
                <Link to={linkTo.url} className={styles.textLink}>
                    {linkTo.label}
                </Link>
            </div>
            <Row>
                {stats.map(stat => (
                    <Col key={stat.value}>
                        <div className={styles.label}>{stat.label}</div>
                        <div className={styles.value}>{stat.value}</div>
                    </Col>
                ))}
            </Row>
        </div>
    )
}
