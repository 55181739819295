/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
import {
    InvestorCommunicationStatsResponse,
    InvestorCommunicationStatsResponseFromJSON,
    InvestorCommunicationStatsResponseFromJSONTyped,
} from './'

/**
 *
 * @export
 * @interface InvestorCommunicationSendStatsResponse
 */
export interface InvestorCommunicationSendStatsResponse {
    /**
     *
     * @type {string}
     * @memberof InvestorCommunicationSendStatsResponse
     */
    communication_id: string
    /**
     *
     * @type {{ [key: string]: InvestorCommunicationStatsResponse; }}
     * @memberof InvestorCommunicationSendStatsResponse
     */
    stats_by_audience: {[key: string]: InvestorCommunicationStatsResponse}
}

export function InvestorCommunicationSendStatsResponseFromJSON(json: any): InvestorCommunicationSendStatsResponse {
    return InvestorCommunicationSendStatsResponseFromJSONTyped(json, false)
}

export function InvestorCommunicationSendStatsResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): InvestorCommunicationSendStatsResponse {
    if (json === undefined || json === null) {
        return json
    }
    return {
        communication_id: json['communication_id'],
        stats_by_audience: mapValues(json['stats_by_audience'], InvestorCommunicationStatsResponseFromJSON),
    }
}

export function InvestorCommunicationSendStatsResponseToJSON(
    value?: InvestorCommunicationSendStatsResponse | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        communication_id: value.communication_id,
        stats_by_audience: mapValues(value.stats_by_audience, InvestorCommunicationStatsResponseToJSON),
    }
}
