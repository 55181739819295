@import '/src/_assets/styles/config.module';
@import '/src/_assets/styles/typography';

.schemeStatsCard {
    $borderRadius: 4px;

    @include cardContainer;
    @include typography(small-text);
    padding-block: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-radius: $borderRadius;

    .title {
        @include typography(h6);
        margin: 0;
    }

    .textLink {
        $anchorColor: colour(Melon500);
        $anchorHoverColor: colour(Melon700);

        @include typography(tiny-text);
        margin: 0;
        color: $anchorColor;
        text-decoration: underline;
        cursor: pointer;
        transition: color 0.2s;

        &:hover {
            color: $anchorHoverColor;
        }
    }

    .label {
        $labelColor: #4c454c;

        @include typography(small-text);
        margin: 0;
        color: $labelColor;
    }

    .value {
        @include typography(h4);
        margin: 0;
    }
}
