// @import '../src/_assets/styles/custom.scss';
.app {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100vh; // fallback for browsers that don't support dvh (see below)
    // dynamic view height
    // https://caniuse.com/viewport-unit-variants
    // https://developer.mozilla.org/en-US/docs/Learn/CSS/Building_blocks/Values_and_units#relative_length_units
    min-height: 100dvh;
    margin: 0 auto;
    padding: 0;
}
