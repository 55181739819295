@import '/src/_assets/styles/config.module';

.callout {
    $backgroundColour: #ffffff;
    margin-top: 20px;
    padding: 12px;
    padding-bottom: 0;
    background-color: $backgroundColour;
    border: 1px solid #e9ecef;
    border-left-width: 4px;
    border-radius: 4px;

    p {
        @include typography(small-text);
    }

    &.secondary {
        border-left-color: var(--bs-secondary);
    }

    &.info {
        border-left-color: var(--bs-info);
    }

    &.warning {
        border-left-color: var(--bs-warning);
    }

    &.danger {
        border-left-color: var(--bs-danger);
    }
}
