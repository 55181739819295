@import '/src/_assets/styles/config.module';
@import '/src/_assets/styles/typography';

.container {
    $borderColor: #e4e4e7;
    padding: 0 24px;
    background: var(--background-bg-background, #ffffff);
    border: 1px solid var(--border-border-input, $borderColor);
    border-radius: var(--radius-rounded-xl, 12px);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    .containerHeader {
        display: flex;
        gap: 8px;
        align-items: center;
        align-self: stretch;
        padding-top: 24px;

        // Ideally we would separate the styling from the semantic tag.
        // We think the best way to achieve this is to use Bootstraps Card components, in particular Card.Title
        // We also considered some patterns in Retail Frontend:
        // - importing this module into every file that needs it
        // - using `typographyOverrides['as-h2']`
        h3 {
            @include typography(h5);
        }
    }

    hr {
        margin: -24px;
        color: $borderColor;
        opacity: 1;
    }
}

.section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
}
