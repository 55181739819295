/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface CreateOrUpdateInvestorCommunicationRequest
 */
export interface CreateOrUpdateInvestorCommunicationRequest {
    /**
     *
     * @type {string}
     * @memberof CreateOrUpdateInvestorCommunicationRequest
     */
    subject: string
    /**
     *
     * @type {string}
     * @memberof CreateOrUpdateInvestorCommunicationRequest
     */
    content: string
    /**
     *
     * @type {string}
     * @memberof CreateOrUpdateInvestorCommunicationRequest
     */
    audience?: string
    /**
     *
     * @type {Date}
     * @memberof CreateOrUpdateInvestorCommunicationRequest
     */
    schedule_send_at: DateTime
}

export function CreateOrUpdateInvestorCommunicationRequestFromJSON(
    json: any,
): CreateOrUpdateInvestorCommunicationRequest {
    return CreateOrUpdateInvestorCommunicationRequestFromJSONTyped(json, false)
}

export function CreateOrUpdateInvestorCommunicationRequestFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CreateOrUpdateInvestorCommunicationRequest {
    if (json === undefined || json === null) {
        return json
    }
    return {
        subject: json['subject'],
        content: json['content'],
        audience: !exists(json, 'audience') ? undefined : json['audience'],
        schedule_send_at: DateTime.fromISO(json['schedule_send_at'], {setZone: true}),
    }
}

export function CreateOrUpdateInvestorCommunicationRequestToJSON(
    value?: CreateOrUpdateInvestorCommunicationRequest | null,
): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        subject: value.subject,
        content: value.content,
        audience: value.audience,
        schedule_send_at: value.schedule_send_at.toJSDate().toISOString(),
    }
}
