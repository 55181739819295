const DEFAULT_FEATURES = {
    SEND_COMMS_TO_WATCHLIST: false,
}
export const FEATURES_BY_COMPANY: Record<string, typeof DEFAULT_FEATURES> = {
    '2795bf5c-066c-4204-842a-36b00df46eb4': {
        // Chorus
        SEND_COMMS_TO_WATCHLIST: true,
    },
} as const

export function isFeatureEnabled(companyId: string, feature: keyof typeof DEFAULT_FEATURES): boolean {
    if (FEATURES_BY_COMPANY[companyId] != null) {
        return FEATURES_BY_COMPANY[companyId][feature]
    }
    return DEFAULT_FEATURES[feature]
}
