import classNames from 'classnames'
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    ExternalLinkIcon,
    SaveAllIcon,
    SaveIcon,
    SendIcon,
    Trash2Icon,
    MailSearchIcon,
} from 'lucide-react'
import React from 'react'
import {Button, ButtonProps} from 'react-bootstrap'
import * as styles from './ButtonWithIcon.module.scss'

// Only support certain icons so we don't have to import the entire Lucide icon library
export const supportedIcons = {
    trash: Trash2Icon,
    save: SaveIcon,
    saveAll: SaveAllIcon,
    externalLink: ExternalLinkIcon,
    arrowRight: ArrowRightIcon,
    arrowLeft: ArrowLeftIcon,
    send: SendIcon,
    mailSearch: MailSearchIcon,
}

export type SupportedIcons = keyof typeof supportedIcons

interface ButtonWithIconProps extends ButtonProps {
    children: React.ReactNode
    className?: string
    leftIcon?: SupportedIcons
    rightIcon?: SupportedIcons
    iconSize?: number
    dataTestId?: string
}

/**
 * ButtonWithIcon is a simple wrapper around the react-bootstrap Button component to support adding an icon to
 * the left or the right of the text.
 *
 * @usage `<ButtonWithIcon rightIcon="send">Click me</ButtonWithIcon>`
 *
 * @returns JSX.Element ButtonWithIcon
 */
export const ButtonWithIcon = (props: ButtonWithIconProps) => {
    const {children, className, leftIcon, rightIcon, iconSize, ...rest} = props

    const LeftIcon = leftIcon ? supportedIcons[leftIcon] : null
    const RightIcon = rightIcon ? supportedIcons[rightIcon] : null

    return (
        <Button {...rest} className={classNames(className, styles.buttonWithIcon)}>
            {LeftIcon && <LeftIcon className={styles.leftIcon} size={iconSize ? iconSize : 24} />}

            {children}

            {RightIcon && (
                <span>
                    <RightIcon className={styles.rightIcon} size={iconSize ? iconSize : 24} />
                </span>
            )}
        </Button>
    )
}
