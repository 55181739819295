/* This file is AUTOMATICALLY GENERATED, do no edit directly */
/* eslint-disable */
// @ts-nocheck
/**
 * Sharesies Open
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/* This file is AUTOMATICALLY GENERATED, do no edit directly */
import {DateTime} from 'luxon'
import {exists, mapValues} from '../runtime'
/**
 *
 * @export
 * @interface SetHasSeenRequest
 */
export interface SetHasSeenRequest {
    /**
     *
     * @type {string}
     * @memberof SetHasSeenRequest
     */
    flag: string
}

export function SetHasSeenRequestFromJSON(json: any): SetHasSeenRequest {
    return SetHasSeenRequestFromJSONTyped(json, false)
}

export function SetHasSeenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetHasSeenRequest {
    if (json === undefined || json === null) {
        return json
    }
    return {
        flag: json['flag'],
    }
}

export function SetHasSeenRequestToJSON(value?: SetHasSeenRequest | null): any {
    if (value === undefined) {
        return undefined
    }
    if (value === null) {
        return null
    }
    return {
        flag: value.flag,
    }
}
