import cn from 'classnames'
import * as React from 'react'
import styles from './ContentContainer.module.scss'

interface ContentContainerProps {
    children: React.ReactNode
    className?: string
}

export const ContentContainer = ({children, className}: ContentContainerProps) => {
    return <div className={cn(styles.container, className, 'd-flex gap-4 flex-column')}>{children}</div>
}

export const ContentContainerHeader = ({children, className}: ContentContainerProps) => {
    return <div className={cn(styles.containerHeader, className)}>{children}</div>
}

export const ContentSection = ({children, className}: ContentContainerProps) => {
    return <div className={cn(styles.section, className)}>{children}</div>
}
